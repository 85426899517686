import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { rephrase } from './api';
function RephraseApp({ userInfo, setUserInfo, showToast }) {
  const [inputText, setInputText] = useState('');
  const [outputHtml, setOutputHtml] = useState('');
  const [copySuccess, setCopySuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [height, setHeight] = useState(500);
  const textareaRef = useRef(null);
  const navigate = useNavigate();
  const [wordCount, setWordCount] = useState(0);


  useEffect(() => {
    const updateHeight = () => {
      if (textareaRef.current) {
        setHeight(textareaRef.current.offsetHeight);
      }
    };

    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  useEffect(() => {
    // Update word count whenever inputText changes
    const words = inputText.trim().split(/\s+/);
    setWordCount(inputText.trim() ? words.length : 0);
  }, [inputText]);

  const handleSubmit = async (e) => { 
    e.preventDefault();
    if (!userInfo) {
      showToast('Please log in to use the rephrase feature.', 'error');
      navigate('/login');
      return;
    }
  
    setIsLoading(true);
  
    try {
      const token = localStorage.getItem('token');
      const data = await rephrase(inputText, 'en', token);
      
      setOutputHtml(data.paraphrasedContent);
      // showToast('delete AI successfully', 'success');
    } catch (err) {
      console.error('Error:', err);
      if (err.message === 'Insufficient credits') {
        showToast("You don't have enough credits. Please purchase more.", 'error');
      } else if (err.message === 'Could not validate credentials, please login again') {
        showToast(err.message, 'error');
        navigate('/login');
      } else {
        showToast(err.message || 'Failed to submit', 'error');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopy = async () => {
    try {
      const tempElement = document.createElement('div');
      tempElement.innerHTML = outputHtml;
      const textToCopy = tempElement.textContent || tempElement.innerText;
      
      await navigator.clipboard.writeText(textToCopy);
      setCopySuccess(true);
      // showToast('Text copied to clipboard', 'success');
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
      showToast('Failed to copy text', 'error');
    }
  };
  const isSubmitDisabled = isLoading || wordCount < 10 || wordCount > 500;

  return (
    <div className="min-h-screen bg-amber-100 flex flex-col p-4">
      <div className="flex-grow flex flex-col space-y-4">
        <div className="flex space-x-4">
          <div className="flex-1 flex flex-col space-y-2">
            <textarea
              ref={textareaRef}
              className="w-full p-3 border border-gray-300 rounded-md resize-none text-lg flex-grow bg-white text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              placeholder="Enter text here..."
              value={inputText}
              onChange={(e) => {
                setInputText(e.target.value);
                setHeight(e.target.offsetHeight);
              }}
              style={{ height }}
            />
            <div className="flex justify-left items-center text-sm">
              <span className="text-gray-500 mr-3">
                Words: {wordCount}
              </span>
              {wordCount < 10 && (
                <p className="text-red-500">Please enter at least 10 words.</p>
              )}
              {wordCount > 500 && (
                <p className="text-red-500">Please enter no more than 500 words.</p>
              )}
            </div>
          </div>
          <div className="flex-1 relative" style={{ height }}>
            <div
              className="w-full h-full p-3 border rounded-md overflow-auto bg-gray-50 text-lg text-left text-black"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(outputHtml) }}
            />
            {outputHtml && (
              <button
                onClick={handleCopy}
                className={`absolute bottom-2 right-2 px-3 py-1 text-sm rounded ${
                  copySuccess ? 'bg-green-500' : 'bg-blue-500'
                } text-white hover:bg-opacity-80 transition duration-300`}
              >
                {copySuccess ? 'Copied!' : 'Copy'}
              </button>
            )}
          </div>
        </div>
        <div className="flex justify-center"> 
          <button
            className="w-[150px] bg-blue-500 text-white py-3 rounded-md hover:bg-blue-600 transition duration-300 text-lg font-semibold disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={handleSubmit}
            disabled={isSubmitDisabled}
          >
            {isLoading ? 'Processing...' : 'Delete AI'}
          </button>
        </div>
      </div>
    </div>
  );
}


export default RephraseApp;