import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import AccountDropdown from './AccountDropdown';
import Toast from './Toast';

const Header = ({ userInfo, handleLogout }) => {
  const navigate = useNavigate();
  
  return (
    <header className="bg-blue-200 text-black p-4">
      <div className="flex justify-between items-center">
        <Link to="/daleteai" className="text-2xl font-bold hover:text-blue-400 transition duration-300">
          AI Deleter
        </Link>
        <h1 className="text-xl font-bold text-black">Most Powerful AI Deleter: 100% AI to 0%</h1>
        <div className="flex items-center space-x-4">
          <button
            className="bg-green-600 text-white px-4 py-2 rounded-md hover:bg-green-700 transition duration-300"
            onClick={() => navigate('/pricing')}
          >
            Purchase
          </button>
          {userInfo ? (
            <AccountDropdown onLogout={handleLogout} userInfo={userInfo} />
          ) : (
            <button
              className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-300"
              onClick={() => navigate('/login')}
            >
              Login
            </button>
          )}
        </div>
      </div>
    </header>
  );
};

const Footer = () => (
  <footer className="bg-gray-900 text-gray-400 p-4 mt-auto">
    <div className="container mx-auto text-center">
      Powered by Venus Horizon
    </div>
  </footer>
);

const Layout = ({ children, userInfo, setUserInfo }) => {
  const navigate = useNavigate();
  const [toast, setToast] = useState({ isOpen: false, message: '', type: 'success' });

  const handleLogout = () => {
    localStorage.removeItem('token');
    setUserInfo(null);
    navigate('/login');
  };

  const showToast = (message, type = 'success') => {
    setToast({ isOpen: true, message, type });
  };

  const hideToast = () => {
    setToast({ ...toast, isOpen: false });
  };

  // Clone children and pass showToast function
  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { showToast, userInfo, setUserInfo });
    }
    return child;
  });

  return (
    <div className="flex flex-col min-h-screen bg-amber-100 text-gray-200">
      <Header userInfo={userInfo} handleLogout={handleLogout} />
      <main className="flex-grow container mx-auto py-0 px-0">
        {childrenWithProps}
      </main>
      <Footer />
      <Toast
        isOpen={toast.isOpen}
        message={toast.message}
        type={toast.type}
        onClose={hideToast}
      />
    </div>
  );
};

export default Layout;