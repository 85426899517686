import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserInfo } from './api';  // 假设我们有这个 API 函数
import BuyButton from './BuyButton';

const PricingPage = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const verifyUser = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setIsLoading(false);
        return;
      }
      try {
        const info = await getUserInfo(token);
        setUserInfo(info);
      } catch (error) {
        console.error('Failed to verify user:', error);
        localStorage.removeItem('token');
        localStorage.removeItem('userInfo');
      } finally {
        setIsLoading(false);
      }
    };
    verifyUser();
  }, []);

  const handleBuy = (stripeLink) => {
    if (!userInfo) {
      navigate('/login');
    } else {
      const email = encodeURIComponent(userInfo.email);
      const linkWithEmail = `${stripeLink}?prefilled_email=${email}`;
      window.location.href = linkWithEmail;
    }
  };

  const plans = [
    {
      name: "Free",
      price: "$0.00",
      details: [
        "Credits: 10",
        "Usage: 10 times ",
        "Can be used by 1 people"
      ],
      stripeLink: ""
    },
    {
      name: "Basic",
      price: "$9.9",
      details: [
        "Credits: 15",
        "Usage: 15 times",
        "About $0.6 per use",
        "Can be used by 1 person"
      ],
      stripeLink: "https://buy.stripe.com/bIYbMbbiwfWx1JCbIY"
    },
    {
      name: "Standard",
      price: "$19.9",
      details: [
        "Credits: 50",
        "Usage: 50 times",
        "About $0.4 per use",
        "Can be used by 1 person"
      ],
      stripeLink: "https://buy.stripe.com/dR68zZcmAeSt3RKcN3"
    },
    {
      name: "Pro",
      price: "$99.9",
      details: [
        "Credits: 300",
        "Usage: 500 times",
        "About $0.2 per use",
        "Can be used by 5 people"
      ],
      stripeLink: "https://buy.stripe.com/5kAg2r9ao8u52NG3cu"
    }
  ];

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-amber-100 min-h-screen flex flex-col items-center justify-center p-4">
      <h1 className="text-4xl text-black font-bold mb-8 text-center">
        We focus on removing AI content,<br />
        achieve zero detection by Turnitin's AI detection.
      </h1>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 w-full max-w-7xl">
        {plans.map((plan, index) => (
          <div key={index} className="bg-gray-800 text-white rounded-lg p-6 flex flex-col relative">
            {plan.name !== "Free" && (
              <div className="absolute top-0 right-0 bg-red-500 text-white px-2 py-1 rounded-tr-lg rounded-bl-lg text-sm font-semibold">
                Limited time
              </div>
            )}
            <h2 className="text-2xl font-semibold mb-4">{plan.name}</h2>
            <p className="text-3xl font-bold mb-4">{plan.price}</p>
            <ul className="flex-grow mb-4">
              {plan.details.map((detail, detailIndex) => (
                <li key={detailIndex} className="flex items-start mb-2">
                  <svg className="w-5 h-5 mr-2 text-green-500 flex-shrink-0 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  <span>{detail}</span>
                </li>
              ))}
            </ul>
            <BuyButton plan={plan} userInfo={userInfo} handleBuy={handleBuy} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PricingPage;